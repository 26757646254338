import { isPlatformServer } from '@angular/common';
import { inject, Injectable, makeStateKey, PLATFORM_ID, StateKey, TransferState } from '@angular/core';
import { Params } from '@angular/router';
import { GenericLogger, MECACHIS_DEPENDENCY_SERVICE } from '@scalefast/ecommerce-core';
import { PageLayoutType } from 'src/app/core/enumerates/page-layout-type.enumerate';
import { Page, PageComponents } from 'src/app/core/interfaces';
import { ContentfulArticle } from 'src/app/core/interfaces/Contentful/Article/ContentfulArticle';
import { ContentfulArticleListBlock } from 'src/app/core/interfaces/Contentful/ArticleListBlock/ContentfulArticleListBlock';
import { ContentfulArticleListBlockContent } from 'src/app/core/interfaces/Contentful/ArticleListBlock/ContentfulArticleListBlockContent';
import { ContentfulCategoryContent } from 'src/app/core/interfaces/Contentful/Category/ContentfulCategoryContent';
import { ContentfulContent } from 'src/app/core/interfaces/Contentful/ContentfulContent';
import { ContentfulSectionContent } from 'src/app/core/interfaces/Contentful/ContentfulSectionContent';
import { ContentfulLayoutZoneBlockContent } from 'src/app/core/interfaces/Contentful/LayoutZone/ContentfulLayoutZoneContent';
import { ContentfulPagePathContent } from 'src/app/core/interfaces/Contentful/PagePath/ContentfulPagePathContent';
import { ContenfulPage } from 'src/app/core/interfaces/Page/page-contenful.interface';
import { PageLayout } from 'src/app/layout/layout.enum';
import { PageContenfulHandler } from 'src/app/pages/page/resolver/page-contenful-handler';
import { isAContentfulResolvedLink } from '../helpers/contentful.helper';
import { ContentfulArticleService } from './contentful-article.service';
import { ContentfulFaqService } from './contentful-faq.service';
import { ContentfulPageService } from './contentful-page.service';
// Due to the Contentful update, new Contentful skeleton types were added to wrap the current Contentful data type. These types are used in the data request of Contentful methods.
// https://www.contentful.com/developers/docs/javascript/tutorials/typescript-in-javascript-client-library/

@Injectable({
  providedIn: 'root',
})
export class ContentfulRenderService {
  private tstate = inject(TransferState);
  private isServer: boolean;
  private platformId = inject(PLATFORM_ID);
  private logger = inject(GenericLogger);
  private mecachisDependencyService = inject(MECACHIS_DEPENDENCY_SERVICE);
  private contentfulFaqService = inject(ContentfulFaqService);
  private contentfulArticleService = inject(ContentfulArticleService);
  private contentfulPageService = inject(ContentfulPageService);

  constructor() {
    this.isServer = isPlatformServer(this.platformId);
  }

  async getContentfulPageContent(
    routeParams: [],
    contentfulPagePathContent?: ContentfulPagePathContent,
  ): Promise<Page<ContenfulPage> | undefined> {
    try {
      const contentfulContent = await this.getContentFullContentWithTransferState(routeParams, contentfulPagePathContent);
      const contentFulStateKey = PageContenfulHandler.getContentFullContentStateKey(routeParams);
      if (this.isServer) {
        this.tstate.set(contentFulStateKey, contentfulContent);
      } else {
        this.tstate.remove(contentFulStateKey);
      }

      if (!this.isSupportedContentfulPage(contentfulContent)) {
        return; // TODO: Page not found
      }

      this.mecachisDependencyService.addContentfulDependency();
      const response = this.removeUnsupportedContentfulComponents(contentfulContent!);
      return {
        pageProvider: 'contenful',
        name: '',
        type: PageLayoutType.CONTENTFUL_PAGE,
        pageType: PageLayout.Default,
        extraData: {
          info: response,
          components: new PageComponents(),
        },
      } as Page<ContenfulPage>;
    } catch (error) {
      this.logger.error('ContentfulRenderService.getContentfulPageContent', error);
      return undefined;
    } finally {
      this.logger.info('ContentfulRenderService.getContentfulPageContent was called');
    }
  }

  private getCategoryContentFromEntry(entry: any): ContentfulCategoryContent {
    const category = entry.fields;
    let categoryContent: ContentfulCategoryContent = {
      name: '',
      title: 'empty-content',
      slug: '',
    };

    if (!isAContentfulResolvedLink(category.slug)) {
      this.logger.debug('ContentfulRenderService.getCategoryContent', categoryContent);
      return categoryContent;
    }

    categoryContent = {
      name: category.name,
      title: category.title,
      slug: category.slug.fields.slug,
    };

    this.logger.debug('ContentfulRenderService.getCategoryContent', categoryContent);
    return categoryContent;
  }

  private async getContentFullContentWithTransferState(
    routeParams: [],
    contentfulPagePathContent?: ContentfulPagePathContent,
  ): Promise<ContentfulSectionContent[] | undefined> {
    const contentFulStateKey = this.getContentFullContentStateKey(routeParams);
    const contentFulStateValue = this.tstate.get(contentFulStateKey, null);

    return contentFulStateValue ?? this.resolveContentfulPage(routeParams, contentfulPagePathContent);
  }

  private async resolveContentfulPage(
    routeParams: [],
    contentfulPagePathContent?: ContentfulPagePathContent,
  ): Promise<ContentfulSectionContent[] | undefined | any> {
    const completeSlug = this.getCompleteSlug(routeParams);
    if (!contentfulPagePathContent || contentfulPagePathContent.id === 'empty-content') {
      return;
    }
    const contentTypeContent = await this.contentfulPageService.getContentTypeFromPagePath(
      contentfulPagePathContent,
      completeSlug,
    );

    if (!contentTypeContent?.length) return;

    return Promise.all(contentTypeContent.map((entry) => this.getContentByContentType(entry)));
  }

  private async getContentByContentType(item: any): Promise<ContentfulSectionContent> {
    switch (item.contentType) {
      case 'articleOverview':
        return this.getArticleOverviewContent(item);
      case 'faq':
        return this.getFaqsContent(item);
      case 'category':
        return this.getCategoryContent(item);
      case 'page':
        return this.getPageContent(item);
      case 'articleListBlock':
        return this.getArticleListBlockContent(item);
    }

    return item;
  }

  private async getArticleOverviewContent(item: any): Promise<ContentfulSectionContent> {
    const articleOverviewContent = this.contentfulArticleService.getArticleOverviewContentFromEntry(item);
    const articleContent = await this.contentfulArticleService.getArticleContent(item.entryId, articleOverviewContent);
    return this.buildContentfulSectionContent({ content: articleContent }, item);
  }

  private getFaqsContent(item: any): ContentfulSectionContent {
    const faqContent = this.contentfulFaqService.getFaqContentFromEntry(item);
    return this.buildContentfulSectionContent({ content: faqContent }, item, PageLayout.Faq);
  }

  private async getCategoryContent(item: any): Promise<ContentfulSectionContent> {
    const categoryContent = await this.getCategoryContentFromEntry(item);
    return this.buildContentfulSectionContent({ content: categoryContent }, item);
  }

  private async getPageContent(item: any): Promise<ContentfulSectionContent> {
    const pageContent = await this.contentfulPageService.getPageContent(item);
    if ('layoutZoneId' in pageContent && pageContent.layoutZoneId) {
      const layoutZoneContent = await this.contentfulPageService.getLayoutZoneContent(pageContent);
      return this.buildContentfulSectionContent({ content: layoutZoneContent }, item, PageLayout.Page);
    } else if ('type' in pageContent && pageContent.type === 'faq-landing') {
      return this.buildContentfulSectionContent({ content: pageContent }, item, PageLayout.Page);
    } else {
      throw new Error('Page content not found');
    }
  }

  private getArticleListBlockContent(item: any): ContentfulSectionContent {
    let articleListBlockContent: ContentfulArticleListBlockContent = {
      title: 'empty-content',
      articleList: [],
      ctaLabel: '',
      ctaLink: {},
    };

    const contentfulArticlesBlock: ContentfulArticleListBlock = item.fields; // FIXME: Why fields object has properties with never values?
    const articleList = contentfulArticlesBlock.articleList ?? [];
    const contentfulArticleList: Array<ContentfulArticle> = articleList.map((article: any) => article.fields);

    articleListBlockContent = {
      title: contentfulArticlesBlock.title,
      articleList: contentfulArticleList,
      ctaLabel: contentfulArticlesBlock.ctaLabel,
      ctaLink: contentfulArticlesBlock.ctaLink,
    };

    this.logger.debug('ContentfulRenderService.getArticleListBlockContent', articleListBlockContent);
    return this.buildContentfulSectionContent({ content: articleListBlockContent }, item);
  }

  /**
   * Build the final ContentfulSectionContent object with the breadcrumb property
   * FIXME: Try to not populate contentfulSectionsContent here, it should be possible to generate this object somewhere else
   */
  private buildContentfulSectionContent(content: ContentfulContent, item: any, layout?: PageLayout): ContentfulSectionContent {
    item.breadcrumb = item.slug.substring(item.slug.lastIndexOf('/') + 1);
    item.contentfulContent = content;
    if (layout) item.layout = layout;
    return item as ContentfulSectionContent; // Cast to allow object building
  }

  /**
   Get the complete slug of the route params
   */
  private getCompleteSlug(routeParams: Params): string {
    let contentSlug = '';
    Object.values(routeParams).forEach((value) => {
      contentSlug = contentSlug + `/${value}`;
    });
    return contentSlug;
  }

  private getContentFullContentStateKey(routeParams: any[]): StateKey<ContentfulSectionContent[]> {
    return makeStateKey(`resolver:contentfull-${routeParams.toString()}`);
  }

  private isSupportedContentfulPage(content?: ContentfulSectionContent[]): boolean {
    this.logger.debug('ContentfulRenderService.isSupportedContentfulPage: Checking if content is renderable');

    if (!content || content.length === 0) {
      this.logger.debug('Contentful no content detected');
      return false;
    }
    if (content.some((content) => content.contentType === 'articleOverview')) {
      // Return true for articles TODO: Check if we can be more strict and articles always return a single content
      // or can be sanitized
      this.logger.debug('Contentful ArticleOverview detected', content);
      return true;
    }
    if (content.some((content) => content.contentType === 'faq')) {
      // Same as ArticleOverview
      this.logger.debug('Contentful faq detected', content);
      return true;
    }
    if (content.some((content) => content.contentType === 'page')) {
      if (content.some((content) => content.contentType === 'articleListBlock')) {
        this.logger.debug('Contentful page with articleListBlock detected in', content);
        return true;
      }
      if (content.some((content) => content.contentType === 'category')) {
        this.logger.debug('Contentful page with category detected in', content);
        return true;
      } else {
        //TODO: Improve renderable page detection
        return true;
      }
    }
    this.logger.debug('Contentful not renderable content detected ', content);
    return false;
  }

  private removeUnsupportedContentfulComponents(content: ContentfulSectionContent[]) {
    const unsupportedContentType = ['productRecommenderBlock', 'blockCarouselBlock'];
    // We can avoid render specific unsopported contentTyps
    return content.map((content) => {
      // We can include al different unsupported components by ContentType
      if (content.contentType === 'page') {
        if (Array.isArray(content.contentfulContent.content)) {
          content.contentfulContent.content = (
            content.contentfulContent.content as Array<ContentfulLayoutZoneBlockContent>
          ).filter((item: any) => !unsupportedContentType.includes(item.contentType));
        }
      }
      return content;
    });
  }
}
