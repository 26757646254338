import { Injectable } from '@angular/core';
import { LegacyConfigService, LocalizationConfigService, SamConfigService } from '@scalefast/config-angular';
import { Product, ProductType } from '@scalefast/core';
import { ProductLogger, ProductService, SamAngularService } from '@scalefast/ecommerce-core';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductWrapperService extends ProductService {
  isOralbES: boolean;
  constructor(
    samAngularService: SamAngularService,
    legacyConfigService: LegacyConfigService,
    samConfigService: SamConfigService,
    localizationConfigService: LocalizationConfigService,
    productLogger: ProductLogger,
  ) {
    super(samAngularService, legacyConfigService, samConfigService, localizationConfigService, productLogger);
    //FIXME: we should have a config for this feature, bundlePriceBehavior in store specific
    this.isOralbES = samConfigService.get().apiContext.v4.projectId === '1299';
  }
  /**
   * This function override the base getProduct$ fn to modify the price
   */
  override getProduct$(productId: number): Observable<Product> {
    return super.getProduct$(productId).pipe(
      map((product) => {
        if (product.type === ProductType.BUNDLE && this.isOralbES) {
          const products = product.bundleProductsV4;
          const recommendedPriceSum = products?.reduce((acc, product) => {
            return acc + parseFloat(product.price?.currencies[0].prices[0].recommendedPrice || '0');
          }, 0);

          const price = recommendedPriceSum || 0;
          product.price.currencies[0].prices[0].oldPrice = price;
        }
        return product;
      }),
    );
  }
}
