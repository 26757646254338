import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { UniversalLocationService } from '@scalefast/ecommerce-core';
import { BehaviorSubject, filter, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UrlService {
  public currentUrl$ = new BehaviorSubject<string>('');

  constructor(
    private router: Router,
    private universalLocationService: UniversalLocationService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.router.events
      .pipe(
        filter(
          (event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd),
        ),
        map((event) => (event instanceof Scroll ? (event.routerEvent as NavigationEnd) : (event as NavigationEnd))),
        map((event) => event.url),
      )
      .subscribe((url) => this.currentUrl$.next(url));
  }

  public getCurrentUrl(): string {
    try {
      const urlLocale = this.locale.toLowerCase();
      const currentUrl =
        this.universalLocationService.getCurrentUrl().indexOf(`/${urlLocale}`) === 0
          ? this.universalLocationService.getCurrentUrl()
          : `/${urlLocale}${this.universalLocationService.getCurrentUrl()}`;

      let currentDomain: string = `${this.universalLocationService.getProtocol()}://${this.universalLocationService.getHostname()}${currentUrl}`;

      if (currentDomain.indexOf('?') > -1) {
        currentDomain = currentDomain.split('?')[0];
      }

      return currentDomain;
    } catch (e) {
      console.error(e); // avoid errors here, we don't want to broke SSR
      return '';
    }
  }
}
