import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { GenericLogger } from '@scalefast/ecommerce-core';

/**
 * Service to manage page navigation outside of Angular routing context.
 */
@Injectable({ providedIn: 'root' })
export class ServerNavigateService {
  private logger = inject(GenericLogger);
  private location = inject(Location);

  /**
   * Navigates to the given route by reloading the page.
   * The URL is constructed from the route parameters and the base href configured in the application.
   * The promise will not resolve under normal conditions since the page reload interrupts script execution.
   * If the navigation does not occur within the specified timeout, the promise is rejected.
   *
   * @param route The route to navigate to.
   * @returns A promise that remains unresolved if the navigation occurs, or is rejected if the timeout (5000ms) is exceeded.
   */
  navigateToRoute(route: ActivatedRouteSnapshot): Promise<never> {
    const routeParams = Object.values(route.params);
    const url = this.getExternalUrl(`/${routeParams.join('/')}`);

    return new Promise((resolve, reject) => {
      this.logger.info(`Navigating to page ${url} using server navigation`);
      window.location.assign(url);

      // Reject the promise if the navigation does not occur within the timeout
      const navigationTimeout = 5000;
      setTimeout(() => {
        reject(new Error(`Server navigation timeout of ${navigationTimeout} ms exceeded for ${url}`));
      }, navigationTimeout);
    });
  }

  /**
   * Constructs an external URL adding the base href to the URL.
   * @param url URL to prepare for external use.
   * @returns The external URL.
   */
  private getExternalUrl(url: string): string {
    return this.location.prepareExternalUrl(url);
  }
}
