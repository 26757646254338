import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LocalizationConfigService, StoreSpecificConfig, StoreSpecificConfigService } from '@scalefast/config-angular';
import { ScriptInjectorService } from './script-injector.service';

@Injectable({ providedIn: 'root' })
export class BazaarvoiceService {
  protected storeConfig: StoreSpecificConfig;

  private readonly BV_URI = 'https://apps.bazaarvoice.com/deployments/'; //$user/$loader/$env/$locale/
  private readonly BV_SCRIPT = 'bv.js';

  private BV_ENV = ''; // production or staging
  private BV_USER = '';
  private BV_LOCALE = '';
  private BV_LOADER = '';

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private localizationConfigService: LocalizationConfigService,
    protected storeConfigService: StoreSpecificConfigService,
    private scriptInjector: ScriptInjectorService,
  ) {
    this.storeConfig = this.storeConfigService.get();

    this.BV_USER = this.storeConfig.integrations.bazaarvoice.user;
    this.BV_LOADER = this.storeConfig.integrations.bazaarvoice.loader;
    this.BV_ENV = this.storeConfig.integrations.bazaarvoice.env;

    let localizationConfig = this.localizationConfigService.get();
    let lang = localizationConfig.currentLang.toUpperCase();
    let lang_lc = lang.toLowerCase();
    this.BV_LOCALE = lang_lc + '_' + lang;
  }

  injectScript(): void {
    this.scriptInjector.injectScript({
      id: 'bv-script',
      async: true,
      defer: false,
      injectOnSSR: false,
      thirdParty: true,
      src: `${this.BV_URI}${this.BV_USER}/${this.BV_LOADER}/${this.BV_ENV}/${this.BV_LOCALE}/${this.BV_SCRIPT}`,
    });
  }
}
