import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StoreSpecificConfig, StoreSpecificConfigService } from '@scalefast/config-angular';
import { ScriptInjectorService } from './script-injector.service';

@Injectable({ providedIn: 'root' })
export class SyndigoService {
  protected storeConfig: StoreSpecificConfig;
  private readonly SYNDIGO_SCRIPT_URI = 'https://content.syndigo.com/site/$syndigo_siteid/tag.js?cv=';
  private SYNDIGO_SITE_ID: string;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    protected storeConfigService: StoreSpecificConfigService,
    private scriptInjector: ScriptInjectorService,
  ) {
    this.storeConfig = this.storeConfigService.get();
    this.SYNDIGO_SITE_ID = this.storeConfig.integrations.syndigo.site_id;
  }

  injectScript(): void {
    this.scriptInjector.injectScript({
      id: 'syndigo-script',
      async: true,
      defer: false,
      injectOnSSR: false,
      thirdParty: true,
      src: this.SYNDIGO_SCRIPT_URI.replace('$syndigo_siteid', this.SYNDIGO_SITE_ID) + Math.floor(Date.now() / 86400000),
    });
  }
}
