export class ProductHelper {
  public static checkIfIsInFeeds(productFeeds: number[], configFeeds: number[]): boolean {
    const set = new Set(productFeeds);
    for (let num of configFeeds) {
      if (set.has(num)) {
        return true;
      }
    }
    return false;
  }
}
