import { Injectable } from '@angular/core';
import { Country } from '../../core/interfaces';

@Injectable({ providedIn: 'root' })
export class CountrySelectService {
  constructor() {}

  protected countries: any[] = [
    {
      value: 'es-ES',
      label: {
        'es-ES': 'España | Español',
        'fr-FR': 'Espagne | Espagnol',
        'it-IT': 'Spagna | Spagnolo',
      },
      link: 'https://oralb.es/es-es',
      checked: false,
    },
    {
      value: 'fr-FR',
      label: {
        'es-ES': 'Francia | Francés',
        'fr-FR': 'France | Français',
        'it-IT': 'Francia | Francese',
      },
      link: 'https://oralb.fr/fr-fr',
      checked: false,
    },
    {
      value: 'it-IT',
      label: {
        'es-ES': 'Italia | Italiano',
        'fr-FR': 'Italie | Italien',
        'it-IT': 'Italia | Italiano',
      },
      link: 'https://shop.oralb.it/',
      checked: false,
    },
  ];

  public getCountries(): Country[] {
    return this.countries;
  }
}
