import { Observable, Observer, Subscription, filter, share } from 'rxjs';

export class EventManager {
  observable: Observable<any>;
  observer?: Observer<any>;

  constructor() {
    this.observable = new Observable((observer: Observer<any>) => {
      this.observer = observer;
    }).pipe(share());
  }

  subscribe(eventName: any, callback: any) {
    const subscriber: Subscription = this.observable
      .pipe(
        filter((event) => {
          return event.name === eventName;
        }),
      )
      .subscribe(callback);
    return subscriber;
  }

  broadcast(event: any) {
    if (this.observer != null) {
      this.observer.next(event);
    }
  }

  destroy(subscriber: Subscription) {
    subscriber.unsubscribe();
  }
}
