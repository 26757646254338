import { isPlatformServer } from '@angular/common';
import { DestroyRef, Inject, Injectable, NgZone, Optional, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LocalizationConfigService, SamConfigService, StoreSpecificConfigService } from '@scalefast/config-angular';
import { AnalyticsService } from '@scalefast/ecommerce-core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PohService } from './poh.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalizationHelper } from 'src/app/shared/helpers/localization.helper';

interface dataProducts {
  name?: string;
  sku?: string;
  price?: number;
  brand?: string;
  category?: string;
  variant?: string;
  variant_sku?: string;
  bundle_products?: string;
  quantity?: number;
  position?: number;
  listtype?: string;
}

export enum eventAction {
  add = 'event_buy_now_add_to_cart',
  remove = 'event_remove_from_cart',
  update = 'event_update_quantity',
}

type PGdataLayerInterface = {
  GTM: {
    SiteLocale: string;
    SiteCountry: string;
    SiteCountryISO3: string;
    SiteCurrency: string;
    SiteBrand: string;
    SiteLanguage: string;
    SitePlatform: string;
    SiteEnvironment: string;
    SiteHost: string;
    SiteTechnicalAgency: string;
    SiteStatus: string;
    SiteTouchpoint: string;
    SiteLocalContainer: string;
    SiteStagingContainer: string;
    GoogleAnalyticsGA4MeasurementID: string;
    ConsentOverlay: string;
    ConsentOverlayID: string;
    SitePrivacyProtection: string;
    ConsentRequiredClientID: string;
    GoogleAnalyticsConsentRequired: string;
  };
  user: {
    type: string;
    email: string;
    ecommID: string;
  };
  session: {
    leadkey: string;
    useragent: string;
  };
  page: {
    url: string;
    title: string;
  };
  content: {
    type: string;
    sub_category: string;
    category: string;
  };
  product: {
    gtin: string;
    name: string;
    brand: string;
    brand_group: string;
    brand_group_code: string;
    sector: string;
    sub_sector: string;
    category: string;
    sub_brand: string;
    segment: string;
    upc: string;
  };
  event: [];
  cd: [];
  ecomm: {
    step: string;
    options: string;
    products: dataProducts[];
    transaction: {
      id: string;
      store: string;
      revenue: string;
      tax: string;
      shipping: string;
      shipping_option: string;
      discount: string;
      order_coupon: string;
      order_coupon_discount: string;
    };
    promo: {
      promo_id: string;
      name: string;
      creative: string;
      position: string;
    };
  };
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsStoreService {
  private GTM_ENV = '';

  private PGdataLayer: PGdataLayerInterface = {
    GTM: {
      SiteLocale: '', // fr-fr ; es-es
      SiteCountry: '', // FR ; ES
      SiteCountryISO3: '',
      SiteCurrency: '', // EUR
      SiteBrand: 'Oral-B',
      SiteLanguage: '', // fr ; es
      SitePlatform: '', // domain
      SiteEnvironment: 'Staging', // Staging ; Prod
      SiteHost: 'Scalefast',
      SiteTechnicalAgency: 'Scalefast',
      SiteStatus: '',
      SiteTouchpoint: '', // FR: 49599 // ES: 49598
      SiteLocalContainer: '', //Using the one from IT, for now, as it has OneTrust // FR: GTM-PVL3929T // ES: GTM-T2RKFF5D
      SiteStagingContainer: '', // Site UAT/RC container ID
      GoogleAnalyticsGA4MeasurementID: '', // FR: G-TTVEVJRR3L // ES: G-QRFTENEM2E
      ConsentOverlay: 'OneTrust', // OneTrust
      ConsentOverlayID: '', //Using the one from IT for now, required for events to be pushed
      SitePrivacyProtection: 'GDPR', // GDPR
      ConsentRequiredClientID: '', //FR: htmllang/6eca7417-dd3e-4a1b-9cff-a361fc9070ca // ES: htmllang/d9c05e5c-7c37-4392-bb3d-c5a8063b8a5a;
      GoogleAnalyticsConsentRequired: '', // GA tracking requires consent (true/false) - optional w/ OneTrust Overlay
    },
    user: {
      type: 'unknown',
      email: '',
      ecommID: '',
    },
    session: {
      leadkey: '',
      useragent: '',
    },
    page: {
      url: '',
      title: '',
    },
    content: {
      type: '',
      sub_category: '',
      category: '',
    },
    product: {
      //only on PDP
      gtin: '',
      name: '',
      brand: '',
      brand_group: '',
      brand_group_code: '',
      sector: '',
      sub_sector: '',
      category: '',
      sub_brand: '',
      segment: '',
      upc: '',
    },
    event: [],
    cd: [],
    ecomm: {
      step: '',
      options: '',
      products: [],
      transaction: {
        id: '',
        store: '',
        revenue: '',
        tax: '',
        shipping: '',
        shipping_option: '',
        discount: '0',
        order_coupon: '',
        order_coupon_discount: '',
      },
      promo: {
        promo_id: '',
        name: '',
        creative: '',
        position: '',
      },
    },
  };

  public google_tag_manager: any;
  public brand: string = 'Oral-B';
  private GTM_TOUCHPOINT = '';
  private GTM_SL_Container = '';
  private GTM_GA_MeasurementID = '';
  private GTM_CO_ID = '';
  pepStorageKey: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private pohService: PohService,
    private analyticsService: AnalyticsService,
    private gtmService: GoogleTagManagerService,
    private localizationConfigService: LocalizationConfigService,
    protected storeSpecificConfigService: StoreSpecificConfigService,
    private zone: NgZone,
    private router: Router,
    private samConfigService: SamConfigService,
    private destroyRef: DestroyRef,
    @Optional() @Inject('IS_BUILDER_EDITOR') private isBuilderEditor: boolean,
  ) {
    // We check if the page is opened from builder's editor mode, and if is, we skip all the anayltics and the cookie banner.
    if (this.isAnalyticsDisabled()) {
      return;
    }
    const storeConfig = this.storeSpecificConfigService.get();
    this.pepStorageKey = this.samConfigService.get().apiContext.v4.pepStorageKey;
    const gtmConfig = storeConfig.GTM;
    this.GTM_ENV = gtmConfig.env;

    this.GTM_TOUCHPOINT = gtmConfig.SiteTouchpoint;
    this.GTM_SL_Container = gtmConfig.SiteLocalContainer;
    this.GTM_GA_MeasurementID = gtmConfig.GoogleAnalyticsGA4MeasurementID;
    this.GTM_CO_ID = gtmConfig.ConsentOverlayID + this.GTM_ENV;

    this.initPGdataLayer();
    window.PGdataLayer = this.PGdataLayer;
    window.gtm_local_container_ready;
    this.gtmService.addGtmToDom();
    this.loadScript();
  }

  /**
   * This function guards most public analytics functions
   * It ensures analytics code is not run in the server or when disabled explicitly
   */
  private isAnalyticsDisabled() {
    const storeConfig = this.storeSpecificConfigService.get();
    const disableThirdParty = storeConfig.disableThirdPartyScripts === 'true';
    return isPlatformServer(this.platformId) || this.isBuilderEditor || disableThirdParty;
  }

  private loadScript() {
    this.zone.runOutsideAngular(() => {
      var isLocalContainerReady = setInterval(() => {
        if (window.hasOwnProperty('PGdataLayer') && window.hasOwnProperty('google_tag_manager')) {
          if (window.PGdataLayer.hasOwnProperty('GTM')) {
            let containerId = window.PGdataLayer.GTM.SiteLocalContainer;
            if (window.hasOwnProperty('google_tag_manager') && window.google_tag_manager.hasOwnProperty(containerId)) {
              var localDl = window.google_tag_manager[containerId].dataLayer,
                activeGroups = localDl.get('OnetrustActiveGroups');

              if (activeGroups) {
                clearInterval(isLocalContainerReady);
                if (!window.hasOwnProperty('gtm_local_container_ready')) {
                  window.gtm_local_container_ready = true;
                  window.dispatchEvent(new CustomEvent('gtm_local_container_ready'));
                }
              }
            }
          }
        }
      }, 20);
    });
  }

  private initPGdataLayer() {
    let localizationConfig = this.localizationConfigService.get();
    let lang = localizationConfig.currentLang.toUpperCase();
    let lang_lc = lang.toLowerCase();
    this.PGdataLayer.GTM.SiteLocale = lang_lc + '-' + lang_lc;
    this.PGdataLayer.GTM.SiteCountry = lang;
    this.PGdataLayer.GTM.SiteCountryISO3 = LocalizationHelper.convertToISO3(lang);
    this.PGdataLayer.GTM.SiteCurrency = localizationConfig.currency;
    this.PGdataLayer.GTM.SiteLanguage = lang_lc;
    this.PGdataLayer.GTM.SiteTouchpoint = this.GTM_TOUCHPOINT;
    this.PGdataLayer.GTM.SiteLocalContainer = this.GTM_SL_Container;
    this.PGdataLayer.GTM.GoogleAnalyticsGA4MeasurementID = this.GTM_GA_MeasurementID;
    this.PGdataLayer.GTM.ConsentOverlayID = this.GTM_CO_ID;
    this.PGdataLayer.GTM.SitePlatform = location.protocol + '//' + location.host;
    this.PGdataLayer.session.useragent = navigator.userAgent;

    //TODO - change this to use services that provide the info instead of accessing directly to sessionStorage
    let sessionSt = window.sessionStorage.getItem(this.pepStorageKey) || '';
    if (sessionSt) {
      let sessionSt_parsed = JSON.parse(sessionSt);
      if (sessionSt_parsed && sessionSt_parsed != '') {
        let user_ = sessionSt_parsed['pep-user'];
        if (user_) {
          let user_id = user_['userID'];
          let user_email = user_['email'];
          this.PGdataLayer.user.type = 'member';
          this.PGdataLayer.user.email = user_email;
          this.PGdataLayer.user.ecommID = user_id;
        }
      }
    }
  }

  public getEvents() {
    if (this.isAnalyticsDisabled()) {
      return;
    }

    // First load if router was already navigated
    if (this.router.navigated) {
      this.pushVirtualPageView();
      this.cleanArrayProducts();
    }

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pushVirtualPageView();
      }

      // FIXME: The NavigationStart event is fired multiple times during page load.
      if (event instanceof NavigationStart) {
        this.cleanArrayProducts();
      }
    });

    this.PGdataLayer.session.useragent = navigator.userAgent;

    this.analyticsService.events$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event: any) => {
      if (event.action == 'add' || event.action == 'remove') {
        // || event.action == "update"
        this.addUpdateRemoveToCart(event);
      }

      if (
        event.type == 'productsDisplay' &&
        (event.action == 'load' || event.action == 'filter' || event.action == 'search-bar-result')
      ) {
        //Category page and Search results page
        this.productImpression(event);
      }

      if (
        event.type == 'productsDisplay' &&
        (event.action == 'load-more' || event.action == 'filter-more' || event.action == 'search-bar-result-more')
      ) {
        //Category page and Search results page
        this.productImpression(event, true);
      }

      if (event.type == 'productsDisplay' && event.action == 'search-bar-result') {
        //Search results page - query
        let params = event.queryParams.split(':');
        this.waitforOneTrust('event_informational_action', 'event_search', params[1], 0, false);
      }

      if (event.type == 'productsDisplay' && event.action == 'search-bar') {
        //Search dropdown - query
        this.waitforOneTrust('event_informational_action', 'event_search', event.queryParams, 0, false);
      }

      /* PDP
        These 2 events happen both in PDP and are about productImpressions
        We cannot reset the array of products in PGdataLayer when 1 of them happens,
        because we may remove the others from the list as they can happen in any order.
      */
      if (event.type == 'productPageDisplayed') {
        //PDP
        this.productPage(event);
      }
      if (event.type == 'carousel') {
        //PDP
        this.productImpressionRelated(event);
      }
      //PDP -----------

      return event;
    });
  }

  miscEvent(category: string, action: string, label: string = '') {
    if (this.isAnalyticsDisabled()) {
      return;
    }
    this.waitforOneTrust(category, action, label, 0, false);
  }

  private addUpdateRemoveToCart(event: any) {
    const action = eventAction[event.action as keyof typeof eventAction];
    this.waitforOneTrust('event_bin_action', action, event.product.refID, 0, false);
  }

  private pushproductOrUpdate(product: any) {
    var found = false;
    for (var i = 0; i < this.PGdataLayer.ecomm.products.length; i++) {
      var elem = this.PGdataLayer.ecomm.products[i];
      if (elem.sku === product.sku) {
        //to not repeat products that are already in PGdataLayer
        found = true;
        this.PGdataLayer.ecomm.products[i] = product;
      }
    }
    if (!found) {
      this.PGdataLayer.ecomm.products.push(product);
    }
  }

  private productPage(event: any) {
    var bundles: string = '';
    var string_variant: string = '';
    bundles = this.getBundleSKUsPDP(event.product);
    string_variant = this.returnVariant(event.product);

    //Product PDP info for PGdataLayer.product
    this.PGdataLayer.product = {
      gtin: event.product.refID,
      name: event.product.descriptions[0]?.title,
      brand: this.brand,
      brand_group: '',
      brand_group_code: '',
      sector: '',
      sub_sector: '',
      category: this.getCategories(event.product.categories, 'categoryName'),
      sub_brand: '',
      segment: '',
      upc: '',
    };
    this.waitforOneTrust('event_informational_action', 'event_view_product_detail_page', event.product.refID, 0, true);

    //Product ecommerce info for PGdataLayer.ecomm.products
    let product_forPGdataLayer = {
      name: event.product.descriptions[0]?.title,
      sku: event.product.refID,
      price: event.product.price.prices[0].price,
      category: this.getCategories(event.product.categories, 'categoryName'),
      brand: this.brand,
      position: event.product.position,
      listtype: 'Product Detail Page',
      variant_sku: string_variant ? event.product.refID : '',
      variant: string_variant,
      bundle_products: bundles,
    };
    this.pushproductOrUpdate(product_forPGdataLayer);
    this.waitforOneTrust('event_informational_action', 'event_send_product_impressions', '', 0, true);
  }

  private productImpressionRelated(event: any) {
    var bundles: string = '';
    var string_variant: string = '';
    var listtype: string = event.action == 'show-related' ? 'Related products' : 'You may also like';

    event.products.forEach((product: any) => {
      bundles = this.getBundleSKUs(product);
      string_variant = this.returnVariant(product);

      let product_forPGdataLayer = {
        name: product.descriptions[0]?.title,
        sku: product.refID,
        price: product.price.prices[0].price,
        category: this.getCategories(product.categories, 'categoryName'),
        brand: this.brand,
        position: product.position,
        listtype: listtype,
        variant_sku: string_variant ? product.refID : '',
        variant: string_variant,
        bundle_products: bundles,
      };
      this.pushproductOrUpdate(product_forPGdataLayer);
    });
    this.waitforOneTrust('event_informational_action', 'event_send_product_impressions', '', 0, true);
  }

  private productImpression(event: any, to_concat: boolean = false) {
    var string_variant: string;
    var bundles: string = '';
    var theProducts: dataProducts[] = [];
    var listtype = event.action == 'search-bar-result' ? 'Search Results' : 'Category Page';

    event.products.forEach((product: any) => {
      bundles = this.getBundleSKUs(product);
      string_variant = this.returnVariant(product);

      theProducts.push({
        name: product.descriptions[0]?.title,
        sku: product.refID,
        price: product.price.prices[0].price,
        category: this.getCategories(product.categories, 'categoryName'),
        brand: this.brand,
        position: product.position,
        listtype: listtype,
        variant_sku: string_variant ? product.refID : '',
        variant: string_variant,
        bundle_products: bundles,
      });
    });

    if (to_concat) {
      this.PGdataLayer.ecomm.products = this.PGdataLayer.ecomm.products.concat(theProducts); //to add the new products to the previous list
    } else {
      this.PGdataLayer.ecomm.products = theProducts; //to not accumulate products from one page to another
    }

    this.waitforOneTrust('event_informational_action', 'event_send_product_impressions', '', 0, true);
  }

  private pg_gtm_event(category: string, action: string, label: string, value: number, interaction: boolean): void {
    const gtmTag = {
      event: 'customEvent',
      GAeventCategory: category || '',
      GAeventAction: action || '',
      GAeventLabel: label || '',
      GAeventValue: value || 0,
      GAeventNonInteraction: interaction,
    };
    this.gtmService.pushTag(gtmTag);
  }

  pg_content(type: string): void {
    if (this.isAnalyticsDisabled()) {
      return;
    }

    this.pohService.isAPohPage(true).subscribe((isPohPage) => {
      if (isPohPage) {
        this.PGdataLayer.content.type = 'poh';
        return (this.PGdataLayer.content.sub_category = 'poh');
      }

      let string_type = type == 'home' ? 'index' : type;

      this.PGdataLayer.content.type = string_type;
      return (this.PGdataLayer.content.sub_category = '');
    });
  }

  private waitforOneTrust(category: string, action: string, label: string, value: number, interaction: boolean): void {
    if (window.hasOwnProperty('gtm_local_container_ready') && window.gtm_local_container_ready == true) {
      this.pg_gtm_event(category, action, label, value, interaction);
    } else {
      window.addEventListener('gtm_local_container_ready', (e) => {
        this.pg_gtm_event(category, action, label, value, interaction);
      });
    }
  }

  private getCategories(data: string[], param: string): string {
    let string_data: string = '';
    if (data !== undefined && data.length >= 0) {
      var array_data: string[] = [];
      data.forEach((data: any) => {
        array_data.push(data?.[param]);
      });
      string_data = array_data.join('|');
      array_data = [];
    }

    return string_data;
  }

  private returnVariant(product: any): string {
    let variant: string[] = [];
    let string_variant: string = '';

    if (
      product.specificV4?.extension?.videogame?.platform !== 'undefined' &&
      product.specificV4?.extension?.videogame?.platform != null
    ) {
      variant.push(product.specificV4?.extension?.videogame?.platform);
    }

    if (
      product.specificV4?.extension?.videogame?.edition !== 'undefined' &&
      product.specificV4?.extension?.videogame?.edition != null
    ) {
      variant.push(product.specificV4?.extension?.videogame?.edition);
    }

    string_variant = variant.join('/');
    return string_variant;
  }

  private getBundleSKUs(product: any) {
    let bundles: string[] = [];
    let bundles_str: string = '';
    if (product.type == 'BUNDLE') {
      bundles = product?.bundleProductsV4.map(function (b: any) {
        return b.refs.refID;
      });
      bundles_str = bundles.join('|');
    }
    return bundles_str;
  }

  private getBundleSKUsPDP(product: any) {
    let bundles: string[] = [];
    let bundles_str: string = '';
    if (product.type == 'BUNDLE') {
      if (product?.specificV4?.bundle_info?.elements_visible_product) {
        bundles = product?.specificV4?.bundle_info?.elements?.elements?.map(function (b: any) {
          return b.refID;
        });
        bundles_str = bundles.join('|');
      }
    }

    return bundles_str;
  }

  private pushVirtualPageView(): void {
    if (document.readyState === 'complete') {
      const gtmTag = {
        event: 'virtualPageview',
      };
      this.gtmService.pushTag(gtmTag);
    }
  }

  private cleanArrayProducts(): void {
    this.PGdataLayer.product = {
      //clean PDP product info, when moving ot another page
      gtin: '',
      name: '',
      brand: '',
      brand_group: '',
      brand_group_code: '',
      sector: '',
      sub_sector: '',
      category: '',
      sub_brand: '',
      segment: '',
      upc: '',
    };
    this.PGdataLayer.ecomm.products = []; //clean array of products
  }
}
