interface ISOCodeTable {
  [ISOCode2: string]: string;
}

export class LocalizationHelper {
  public static readonly ISO_CODE_TABLE: ISOCodeTable = {
    ES: 'ESP',
    FR: 'FRA',
    IT: 'ITA',
  };

  public static convertToISO3(ISOCode2: string): string {
    return this.ISO_CODE_TABLE[ISOCode2] ?? '';
  }
}
