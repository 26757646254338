import { inject, Injectable } from '@angular/core';
import { GenericLogger } from '@scalefast/ecommerce-core';
import type { Entry } from 'contentful';
import { ContentfulFAQAppDetails } from 'src/app/core/interfaces/Contentful/AppsPromo/ContentfulFAQAppDetails';
import { NoChainModifier } from 'src/app/core/interfaces/Contentful/ContentfulChainModifier';
import { ContentfulFAQContent } from 'src/app/core/interfaces/Contentful/FAQ/ContentfulFAQContent';
import { ContentfulFAQSkeleton } from 'src/app/core/interfaces/Contentful/FAQ/ContentfulFAQSkeleton';
import { ContentfulFAQTopic } from 'src/app/core/interfaces/Contentful/FAQ/ContentfulFAQTopic';
import { ContentfulFeedback } from 'src/app/core/interfaces/Contentful/Feedback/ContentfulFeedback';
import { MetaData } from 'src/app/core/interfaces/Contentful/MetaData/MetaData';
import { MetaDataSkeleton } from 'src/app/core/interfaces/Contentful/MetaData/MetaDataSkeleton';
import { ContentfulFAQLandingPageContent } from 'src/app/core/interfaces/Contentful/Page/ContentfulFAQLandingPageContent';
import { ContentfulFAQTopicContent } from 'src/app/core/interfaces/Contentful/Page/ContentfulFAQTopicContent';
import { ContentfulFAQTopicContentSkeleton } from 'src/app/core/interfaces/Contentful/Page/ContentfulFAQTopicContentSkeleton';
import { ContentfulZone } from 'src/app/core/interfaces/Contentful/Zone/ContentfulZone';
import { ContentfulZoneSkeleton } from 'src/app/core/interfaces/Contentful/Zone/ContentfulZoneSkeleton';
import { isAContentfulResolvedLink } from '../helpers/contentful.helper';
import { ContentfulBaseService } from './contentful-base.service';
import { ContentfulClientService } from './contentful-client.service';

@Injectable({
  providedIn: 'root',
})
export class ContentfulFaqService {
  #logger = inject(GenericLogger);
  #contentfulClientService = inject(ContentfulClientService);
  #contentfulBaseService = inject(ContentfulBaseService);

  getFaqContentFromEntry(entry: any): ContentfulFAQContent {
    let faqContent: ContentfulFAQContent = {
      name: '',
      question: 'empty-content',
      answer: '',
      description: '',
      slug: '',
      faqAppDetailsBody: '',
      faqAppDetailsImageAltLogo: '',
      faqAppDetailsImageLogoMobile: '',
      faqAppDetailsImageBackgroundMobile: '',
      faqAppDetailsImageBackgroundDesktop: '',
    };

    const contentfulFaqItems = entry.fields;

    if (!isAContentfulResolvedLink(contentfulFaqItems.slug)) {
      this.#logger.debug('ContentfulFaqService.getFaqContent', faqContent);
      return faqContent;
    }

    let faqFeedback: ContentfulFeedback = {
      name: '',
      title: '',
      desktopImage: {
        sys: {
          id: '',
        },
      },
      feedbackYesLabel: '',
      feedbackNoLabel: '',
      feedbackMessage: '',
    };

    let faqAppdetails: ContentfulFAQAppDetails = {
      body: '',
      links: [
        {
          url: '',
          title: '',
          imageUrl: '',
        },
      ],
      imageAltLogo: '',
      imageLogoMobile: '',
      imageBackgroundMobile: '',
      imageBackgroundDesktop: '',
    };

    if (isAContentfulResolvedLink(contentfulFaqItems.faqFeedback)) {
      faqFeedback = contentfulFaqItems.faqFeedback.fields;
    }

    if (contentfulFaqItems.faqAppdetails) {
      faqAppdetails = contentfulFaqItems.faqAppdetails;
    }

    let metaDescription: string | undefined = undefined;
    let metadata: Entry<MetaDataSkeleton, NoChainModifier> | undefined = undefined;

    if (isAContentfulResolvedLink(contentfulFaqItems.metadata)) {
      metaDescription = contentfulFaqItems.metadata.fields.metaDescription;
      metadata = contentfulFaqItems.metadata;
    }

    faqContent = {
      name: contentfulFaqItems.name,
      question: contentfulFaqItems.question,
      answer: contentfulFaqItems.answer,
      description: metaDescription,
      slug: contentfulFaqItems.slug.fields.slug,
      meta: metadata,
      faqFeedback: faqFeedback,
      faqAppDetailsBody: faqAppdetails.body,
      faqAppDetailsLinks: faqAppdetails.links,
      faqAppDetailsImageAltLogo: faqAppdetails.imageAltLogo,
      faqAppDetailsImageLogoMobile: faqAppdetails.imageLogoMobile,
      faqAppDetailsImageBackgroundMobile: faqAppdetails.imageBackgroundMobile,
      faqAppDetailsImageBackgroundDesktop: faqAppdetails.imageBackgroundDesktop,
    };

    this.#logger.debug('ContentfulFaqService.getFaqContent', faqContent);
    return faqContent;
  }

  async getFaqContent(contentId: string): Promise<ContentfulFAQContent> {
    let faqContent: ContentfulFAQContent = {
      name: '',
      question: 'empty-content',
      answer: '',
      description: '',
      slug: '',
      faqAppDetailsBody: '',
      faqAppDetailsImageAltLogo: '',
      faqAppDetailsImageLogoMobile: '',
      faqAppDetailsImageBackgroundMobile: '',
      faqAppDetailsImageBackgroundDesktop: '',
    };

    try {
      const contentfulQuery = {
        content_type: 'faq',
        'sys.id': contentId,
      };
      const contentfulClient = await this.#contentfulClientService.getClient();
      const faqEntries = await contentfulClient.getEntries<ContentfulFAQSkeleton>(contentfulQuery);

      if (faqEntries.total === 0 || !isAContentfulResolvedLink(faqEntries.items[0].fields.slug)) {
        this.#contentfulBaseService.notifyContentfulEmptyResults(contentfulQuery);
        return faqContent;
      }

      let faqFeedback: ContentfulFeedback = {
        name: '',
        title: '',
        desktopImage: {
          sys: {
            id: '',
          },
        },
        feedbackYesLabel: '',
        feedbackNoLabel: '',
        feedbackMessage: '',
      };

      let faqAppdetails: ContentfulFAQAppDetails = {
        body: '',
        links: [
          {
            url: '',
            title: '',
            imageUrl: '',
          },
        ],
        imageAltLogo: '',
        imageLogoMobile: '',
        imageBackgroundMobile: '',
        imageBackgroundDesktop: '',
      };

      if (isAContentfulResolvedLink(faqEntries.items[0].fields.faqFeedback)) {
        faqFeedback = faqEntries.items[0].fields.faqFeedback.fields;
      }

      if (faqEntries.items[0].fields.faqAppdetails) {
        faqAppdetails = faqEntries.items[0].fields.faqAppdetails;
      }

      let metaDescription: string | undefined = undefined;
      let metadata: Entry<MetaDataSkeleton, NoChainModifier> | undefined = undefined;

      if (isAContentfulResolvedLink(faqEntries.items[0].fields.metadata)) {
        metaDescription = faqEntries.items[0].fields.metadata.fields.metaDescription;
        metadata = faqEntries.items[0].fields.metadata;
      }

      faqContent = {
        name: faqEntries.items[0].fields.name,
        question: faqEntries.items[0].fields.question,
        answer: faqEntries.items[0].fields.answer,
        description: metaDescription,
        slug: faqEntries.items[0].fields.slug.fields.slug,
        meta: metadata,
        faqFeedback: faqFeedback,
        faqAppDetailsBody: faqAppdetails.body,
        faqAppDetailsLinks: faqAppdetails.links,
        faqAppDetailsImageAltLogo: faqAppdetails.imageAltLogo,
        faqAppDetailsImageLogoMobile: faqAppdetails.imageLogoMobile,
        faqAppDetailsImageBackgroundMobile: faqAppdetails.imageBackgroundMobile,
        faqAppDetailsImageBackgroundDesktop: faqAppdetails.imageBackgroundDesktop,
      };

      return faqContent;
    } catch (error) {
      this.#logger.error('ContentfulFaqService.getFaqContent', error);
      return faqContent;
    } finally {
      this.#logger.debug('ContentfulFaqService.getFaqContent', faqContent);
    }
  }

  async getFAQLandingContent(contentId: string, metaDataFields: MetaData): Promise<ContentfulFAQLandingPageContent> {
    let contentfulFAQLandingPageContent = undefined;

    const contentfulQuery = {
      content_type: 'zone',
      'sys.id': contentId,
    };

    const contentfulClient = await this.#contentfulClientService.getClient();
    const contentfulZoneEntries = await contentfulClient.getEntries<ContentfulZoneSkeleton>(contentfulQuery);
    const contentfulZone: ContentfulZone = contentfulZoneEntries.items[0].fields;
    const contentfulFAQLandingPage = contentfulZone.blocks[0].fields;
    const openGraphImageId = contentfulFAQLandingPage.heroBackgroundBanner.sys.id;
    const contentType = contentfulZoneEntries.items[0].sys.contentType.sys.id;
    const topicsIds: Array<string> = contentfulFAQLandingPage.topics.map((topic) => topic.sys.id);
    const [topics, contentfulCloudinaryImage] = await Promise.all([
      Promise.all(topicsIds.map((topicId) => this.#getFAQTopic(topicId))),
      this.#contentfulBaseService.getCloudinaryImage(openGraphImageId),
    ]);

    const openGraphImageUrl = contentfulCloudinaryImage[0]?.asset?.fields.file?.url;

    contentfulFAQLandingPageContent = {
      meta: metaDataFields,
      type: 'faq-landing',
      title: contentfulFAQLandingPage.pageTitle,
      description: '',
      openGraphImageId: openGraphImageId,
      openGraphImageUrl: openGraphImageUrl,
      topicsTitle: contentfulFAQLandingPage.searchTitle,
      topics: topics,
      contentType,
    };

    return contentfulFAQLandingPageContent;
  }

  async #getFAQTopic(contentId: string): Promise<ContentfulFAQTopic> {
    let result: ContentfulFAQTopic = {
      title: '',
      faqs: [],
    };

    const contentfulQuery = {
      content_type: 'faqTopic',
      'sys.id': contentId,
    };

    const contentfulClient = await this.#contentfulClientService.getClient();
    const contentfulFAQTopicContent = await contentfulClient.getEntries<ContentfulFAQTopicContentSkeleton>(contentfulQuery);
    const topicData = contentfulFAQTopicContent.items[0] as unknown as ContentfulFAQTopicContent;
    const faqs = topicData.fields.faqs.map(({ fields, sys }) => ({
      name: fields.name,
      id: sys.id,
      question: fields.question,
      answer: fields.answer,
    }));

    result = {
      title: topicData.fields.topic,
      topicIcon: topicData.fields.topicIcon.fields.asset.fields.file?.url,
      topicHoverIcon: topicData.fields.topicHoverIcon.fields.asset.fields.file?.url,
      faqs: faqs,
    };

    return result;
  }
}
