import { Injectable, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { StoreSpecificConfig, StoreSpecificConfigService } from '@scalefast/config-angular';
import { LocalizationConfigService } from '@scalefast/config-angular';
import { Router } from '@angular/router';
import { UrlService } from './url.service';

export interface MetaTags {
  page?: string;
  title?: string;
  description?: string;
  keywords?: string;
  robots?: string;
  imageSrc?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  protected storeConfig: StoreSpecificConfig;
  private localizationConfig: StoreSpecificConfig;
  private SEO_STORE = '';
  private SEO_TITLE_HOME = '';
  private SEO_DESC = '';
  private IMAGE_OG = '';
  private IMAGE_TW = '';
  private lang_url = '';

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(DOCUMENT) private document: Document,
    private urlService: UrlService,
    private meta: Meta,
    private router: Router,
    private titleService: Title,
    protected storeConfigService: StoreSpecificConfigService,
    private localizationConfigService: LocalizationConfigService,
  ) {
    this.storeConfig = this.storeConfigService.get();

    if (this.storeConfig.seo) {
      this.SEO_STORE = this.storeConfig.seo.store;
      this.SEO_TITLE_HOME = this.storeConfig.seo.homepage_title;
      this.SEO_DESC = this.storeConfig.seo.desc;
      this.IMAGE_OG = this.storeConfig.seo.og_image;
      this.IMAGE_TW = this.storeConfig.seo.tw_image;
    }

    this.localizationConfig = this.localizationConfigService.get();
    let lang = this.localizationConfig.currentLang.toUpperCase();
    let lang_lc = lang.toLowerCase();
    this.lang_url = lang_lc + '-' + lang_lc;
  }

  private setTitle(title: string) {
    const pattern = /\b(oral\s*-\s*b|oral\s+b)\b/i;
    /** @Note Contentful meta - data already contains the Oral-B tag at the end of the string*/
    if (title && pattern.test(title)) {
      return this.titleService.setTitle(title);
    }

    let tag_title = title !== '' ? title + ' | ' + this.SEO_STORE : this.SEO_TITLE_HOME + ' | ' + this.SEO_STORE;
    this.titleService.setTitle(tag_title);
  }

  private setDescription(description: string) {
    let meta_desc = description !== '' ? description : this.SEO_DESC;
    this.meta.updateTag({ name: 'description', content: meta_desc });
  }

  private setKeywords(keywords: string) {
    if (keywords !== '') {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    }
  }

  private setRobots(robots: string) {
    this.meta.updateTag({ name: 'robots', content: robots });
  }

  private setType(ogtype: string) {
    this.meta.updateTag({ property: 'og:type', content: ogtype });
  }

  private setImage(imageSrc: string) {
    let og_image = this.IMAGE_OG;
    let tw_image = this.IMAGE_TW;

    if (imageSrc) {
      og_image = tw_image = imageSrc;
    }
    this.meta.updateTag({ property: 'og:image', content: og_image });
    this.meta.updateTag({ property: 'twitter:image', content: tw_image });
  }

  private setUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  private setCardType(imageSrc: string) {
    let cardType = 'summary';
    if (imageSrc !== '') {
      cardType = 'summary_large_image';
    }
    this.meta.updateTag({ name: 'twitter:card', content: cardType });
  }

  private destroyCanonical() {
    const els = this.document.querySelectorAll("link[rel='canonical']");
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }

  private setCanonical(canonical: string) {
    this.destroyCanonical();
    let link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', canonical);
  }

  setMetaTags(metaTags: MetaTags) {
    const { page, title, description, keywords, robots, imageSrc } = metaTags;
    let conf_title,
      conf_description,
      conf_keywords,
      conf_robots,
      conf_imageSrc: string = '';

    const url = this.urlService.getCurrentUrl();

    if (page && this.storeConfig.seo.pages.hasOwnProperty(page)) {
      conf_title = this.storeConfig.seo?.pages[page]?.title;
      conf_description = this.storeConfig.seo?.pages[page]?.desc;
      conf_keywords = this.storeConfig.seo?.pages[page]?.keywords;
      conf_robots = this.storeConfig.seo?.pages[page]?.robots;
      conf_imageSrc = this.storeConfig.seo?.pages[page]?.imageSrc;
    }

    this.setTitle(title ?? conf_title ?? '');
    this.setDescription(description ?? conf_description ?? '');
    this.setCanonical(url);
    this.setKeywords(keywords ?? conf_keywords ?? '');

    this.setRobots(robots ?? conf_robots ?? 'index,follow');

    this.setType('website');
    this.setImage(imageSrc ?? conf_imageSrc ?? '');
    this.setUrl(url);
    this.setCardType(imageSrc ?? '');
  }
}
