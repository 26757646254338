import { inject, Injectable } from '@angular/core';
import { GenericLogger } from '@scalefast/ecommerce-core';
import type { Entry, UnresolvedLink } from 'contentful';
import { ArticleContentException } from 'src/app/core/Exceptions/Contentful/ArticleContentException';
import { ArticleOverviewContentException } from 'src/app/core/Exceptions/Contentful/ArticleOverviewContentException';
import { CategoryContentException } from 'src/app/core/Exceptions/Contentful/CategoryContentException';
import { ContentfulArticle } from 'src/app/core/interfaces/Contentful/Article/ContentfulArticle';
import { ContentfulArticleContent } from 'src/app/core/interfaces/Contentful/Article/ContentfulArticleContent';
import { ContentfulArticleSkeleton } from 'src/app/core/interfaces/Contentful/Article/ContentfulArticleSkeleton';
import { ContentfulArticleOverviewContent } from 'src/app/core/interfaces/Contentful/ArticleOverview/ContentfulArticleOverviewContent';
import { ContentfulArticleOverviewSkeleton } from 'src/app/core/interfaces/Contentful/ArticleOverview/ContentfulArticleOverviewSkeleton';
import { ContentfulArticleShareContent } from 'src/app/core/interfaces/Contentful/ArticleShareContent/ContentfulArticleShareContent';
import { ContentfulCategoryContent } from 'src/app/core/interfaces/Contentful/Category/ContentfulCategoryContent';
import { ContentfulCategorySkeleton } from 'src/app/core/interfaces/Contentful/Category/ContentfulCategorySkeleton';
import { ContentfulCloudinaryImage } from 'src/app/core/interfaces/Contentful/CloudinaryImage/ContentfulCloudinaryImage';
import { ContentfulCloudinaryImageSkeleton } from 'src/app/core/interfaces/Contentful/CloudinaryImage/ContentfulCloudinaryImageSkeleton';
import { NoChainModifier } from 'src/app/core/interfaces/Contentful/ContentfulChainModifier';
import { ContentfulProductOverview } from 'src/app/core/interfaces/Contentful/ProductOverview/ContentfulProductOverview';
import { ContentfulProductOverviewContent } from 'src/app/core/interfaces/Contentful/ProductOverview/ContentfulProductOverviewContent';
import { ContentfulProductOverviewSkeleton } from 'src/app/core/interfaces/Contentful/ProductOverview/ContentfulProductOverviewSkeleton';
import { isAContentfulResolvedAsset, isAContentfulResolvedLink } from '../helpers/contentful.helper';
import { ContentfulBaseService } from './contentful-base.service';
import { ContentfulClientService } from './contentful-client.service';

@Injectable({
  providedIn: 'root',
})
export class ContentfulArticleService {
  #logger = inject(GenericLogger);
  #contentfulClientService = inject(ContentfulClientService);
  #contentfulBaseService = inject(ContentfulBaseService);

  async getArticleContent(
    contentId: string,
    contentfulArticleOverview: ContentfulArticleOverviewContent,
  ): Promise<ContentfulArticleContent> {
    let contentfulArticleContent: ContentfulArticleContent = {};
    try {
      // TODO: Check if this query is working as expected
      const contentfulQuery = {
        links_to_entry: contentId,
        include: 4 as const, //  Minimum functional value. This cast is needed to avoid typescript error in the getEntries method
      };

      const contentfulClient = await this.#contentfulClientService.getClient();
      const articleEntries = await contentfulClient.getEntries<ContentfulArticleSkeleton>(contentfulQuery);

      // If there's more than one article related to the articleOverview
      let contentfulArticle: ContentfulArticle;
      if (articleEntries.items.length > 1) {
        contentfulArticle = this.#getContentfulFilteredArticle(contentId, articleEntries.items);
      } else {
        contentfulArticle = articleEntries.items[0].fields;
      }

      const productIds = (contentfulArticle.relatedProducts ?? []).map((product) => product.sys.id);
      const articleIds = (contentfulArticle.relatedArticles ?? []).map((article) => article.sys.id);

      if (productIds.length > 0 && articleIds.length > 0) {
        contentfulArticleContent = await this.#getArticleWithRelatedProductsAndArticlesContent(
          productIds,
          articleIds,
          contentfulArticleOverview,
          contentfulArticle,
        );
      } else if (productIds.length > 0) {
        contentfulArticleContent = await this.#getArticleWithRelatedProductsContent(
          productIds,
          contentfulArticleOverview,
          contentfulArticle,
        );
      } else if (articleIds.length > 0) {
        contentfulArticleContent = await this.#getArticleWithRelatedArticlesContent(
          articleIds,
          contentfulArticleOverview,
          contentfulArticle,
        );
      } else {
        // No relatedProducts or relatedArticles, handle the base case
        contentfulArticleContent = await this.#formatArticleContent(contentfulArticleOverview, contentfulArticle);
      }

      return contentfulArticleContent;
    } catch (error) {
      if (error instanceof Error) {
        throw new ArticleContentException(error.message, { cause: error });
      }

      throw error;
    } finally {
      this.#logger.debug('ContentfulArticleService.getArticleContent', contentfulArticleContent);
    }
  }

  getArticleOverviewContentFromEntry(entry: any): any {
    let auxItem = { ...entry };
    const fieldContent = auxItem.fields;

    let thumbnailImage = this.#resolveCloudinaryImageFromEntry(fieldContent.thumbnailImage);
    let slug = isAContentfulResolvedLink(fieldContent.slug) ? fieldContent.slug : undefined;

    auxItem = {
      name: fieldContent.name,
      title: fieldContent.title,
      description: fieldContent.description,
      thumbnailImage: thumbnailImage,
      thumbnailImageUrl: thumbnailImage?.asset?.fields.file?.url,
      slug: slug?.fields.slug,
    };

    this.#logger.debug('ContentfulArticleService.getArticleOverviewContent', auxItem);

    return auxItem;
  }

  #resolveCloudinaryImageFromEntry(
    image?: UnresolvedLink<'Entry'> | Entry<ContentfulCloudinaryImageSkeleton, NoChainModifier>,
  ): ContentfulCloudinaryImage | undefined {
    if (!isAContentfulResolvedLink(image)) {
      return undefined;
    }

    // imageRendition is required in Contentful Cloudinary Image content type in Contentful
    if (!isAContentfulResolvedLink(image.fields.imageRendition)) {
      throw new Error('imageRendition is required but could not be resolved');
    }

    const imageRendition = image.fields.imageRendition.fields;
    const asset = isAContentfulResolvedAsset(image.fields.asset) ? image.fields.asset : undefined;

    return {
      ...image.fields,
      imageRendition,
      asset,
    };
  }

  /**
    Filters between all the articles related to the articleOverview and return the one with the similar id
   */
  #getContentfulFilteredArticle(
    articleOverviewId: string,
    articles: Entry<ContentfulArticleSkeleton, NoChainModifier>[],
  ): ContentfulArticle {
    const articleFound = articles.find((article) => {
      const fields: ContentfulArticle = article.fields; // FIXME: Why fields object has properties with never values?
      return fields.articleOverview.sys.id === articleOverviewId;
    });

    return articleFound ? articleFound.fields : articles[0].fields;
  }

  #getArticleShareContent(contentfulArticle: ContentfulArticle): ContentfulArticleShareContent {
    const articleShareContent: ContentfulArticleShareContent = contentfulArticle.articleShareContent;
    if (articleShareContent) {
      articleShareContent.hasFacebook = Boolean(articleShareContent?.fields?.facebookShareLabel.sys.id);
      articleShareContent.hasTwitter = Boolean(articleShareContent?.fields?.twitterShareLabel.sys.id);
    }
    return articleShareContent;
  }

  async #getArticleWithRelatedProductsAndArticlesContent(
    productIds: string[],
    articleIds: string[],
    contentfulArticleOverview: ContentfulArticleOverviewContent,
    contentfulArticle: ContentfulArticle,
  ): Promise<ContentfulArticleContent> {
    const categoryId = contentfulArticle.category?.sys.id ?? '';
    const biographyContent = contentfulArticle.biographyContent;
    const biographyImageId = contentfulArticle.biographyContent?.fields.image.sys.id ?? '';
    const formattedContents = this.#contentfulBaseService.formatContentfulRichBlockContent(contentfulArticle.contents);
    const [biographyImage, productContent, relatedArticleContent, category] = await Promise.all([
      this.#contentfulBaseService.getCloudinaryImage(biographyImageId),
      this.#getArticleWithRelatedProductsContent(productIds, contentfulArticleOverview, contentfulArticle),
      this.#getArticleWithRelatedArticlesContent(articleIds, contentfulArticleOverview, contentfulArticle),
      categoryId ? this.#getCategoryContent(categoryId) : Promise.resolve(undefined),
    ]);

    const biographyImageUrl = biographyImage[0]?.asset?.fields.file?.url;
    const articleContent: ContentfulArticleContent = {
      meta: contentfulArticle.metadata,
      overview: contentfulArticleOverview,
      biography: biographyContent
        ? {
            name: contentfulArticle.biographyContent?.fields.name || '',
            title: contentfulArticle.biographyContent?.fields.title || '',
            classNames: contentfulArticle.biographyContent?.fields.classNames || '',
            image: biographyImageUrl,
            description: contentfulArticle.biographyContent?.fields.description || '',
            articleListsForBiographyModal: contentfulArticle.articleListsForBiographyModal.map((item: any) => ({
              title: item.fields.title || '',
              id: item.fields.slug.sys.id || '',
              slug: item.fields.slug.fields.slug || '',
            })),
          }
        : undefined,
      contents: formattedContents,
      relatedProducts: productContent.relatedProducts,
      relatedArticles: relatedArticleContent.relatedArticles,
      articleShareContent: this.#getArticleShareContent(contentfulArticle),
    };

    if (category) {
      articleContent.category = category;
    }

    return articleContent;
  }

  async #getArticleWithRelatedProductsContent(
    productIds: Array<string>,
    contentfulArticleOverview: ContentfulArticleOverviewContent,
    contentfulArticle: ContentfulArticle,
  ): Promise<ContentfulArticleContent> {
    const categoryId = contentfulArticle.category?.sys.id ?? '';
    const biographyImageId = contentfulArticle.biographyContent?.fields.image.sys.id ?? '';

    const formattedContents = this.#contentfulBaseService.formatContentfulRichBlockContent(contentfulArticle.contents);
    const [productOverviews, biographyImage, category] = await Promise.all([
      Promise.all(productIds.map((productId) => this.#getProductOverviewContent(productId))),
      this.#contentfulBaseService.getCloudinaryImage(biographyImageId),
      categoryId ? this.#getCategoryContent(categoryId) : Promise.resolve(undefined),
    ]);

    const biographyImageUrl = biographyImage[0]?.asset?.fields.file?.url;
    const biographyContent = contentfulArticle.biographyContent;
    const articleContent: ContentfulArticleContent = {
      meta: contentfulArticle.metadata,
      overview: contentfulArticleOverview,
      biography: biographyContent
        ? {
            name: contentfulArticle.biographyContent?.fields.name || '',
            title: contentfulArticle.biographyContent?.fields.title || '',
            classNames: contentfulArticle.biographyContent?.fields.classNames || '',
            image: biographyImageUrl,
            description: contentfulArticle.biographyContent?.fields.description || '',
            articleListsForBiographyModal: contentfulArticle.articleListsForBiographyModal.map((item: any) => ({
              title: item.fields.title || '',
              id: item.fields.slug.sys.id || '',
              slug: item.fields.slug.fields.slug || '',
            })),
          }
        : undefined,
      contents: formattedContents,
      relatedProducts: productOverviews,
      articleShareContent: this.#getArticleShareContent(contentfulArticle),
    };

    if (category) {
      articleContent.category = category;
    }

    return articleContent;
  }

  async #getArticleWithRelatedArticlesContent(
    articleIds: Array<string>,
    contentfulArticleOverview: ContentfulArticleOverviewContent,
    contentfulArticle: ContentfulArticle,
  ): Promise<ContentfulArticleContent> {
    const categoryId = contentfulArticle.category?.sys.id ?? '';
    const biographyImageId = contentfulArticle.biographyContent?.fields.image.sys.id || '';

    const formattedContents = this.#contentfulBaseService.formatContentfulRichBlockContent(contentfulArticle.contents);
    const [articleOverviews, biographyImage, category] = await Promise.all([
      Promise.all(articleIds.map((articleId) => this.#getArticleOverviewContent(articleId))),
      this.#contentfulBaseService.getCloudinaryImage(biographyImageId),
      categoryId ? this.#getCategoryContent(categoryId) : Promise.resolve(undefined),
    ]);

    const biographyImageUrl = biographyImage[0]?.asset?.fields.file?.url;

    let biography = undefined;
    if (contentfulArticle.biographyContent) {
      biography = {
        name: contentfulArticle.biographyContent?.fields.name || '',
        title: contentfulArticle.biographyContent?.fields.title || '',
        classNames: contentfulArticle.biographyContent?.fields.classNames || '',
        image: biographyImageUrl,
        description: contentfulArticle.biographyContent?.fields.description || '',
        articleListsForBiographyModal: contentfulArticle.articleListsForBiographyModal.map((item: any) => ({
          title: item.fields.title || '',
          id: item.fields.slug.sys.id || '',
          slug: item.fields.slug.fields.slug || '',
        })),
      };
    }

    const articleContent: ContentfulArticleContent = {
      meta: contentfulArticle.metadata,
      overview: contentfulArticleOverview,
      biography,
      contents: formattedContents,
      relatedArticles: articleOverviews,
      articleShareContent: this.#getArticleShareContent(contentfulArticle),
    };

    if (category) {
      articleContent.category = category;
    }

    return articleContent;
  }

  async #formatArticleContent(
    contentfulArticleOverview: ContentfulArticleOverviewContent,
    contentfulArticle: ContentfulArticle,
  ): Promise<ContentfulArticleContent> {
    const categoryId = contentfulArticle.category?.sys.id ?? '';
    const biographyImageId = contentfulArticle.biographyContent?.fields.image.sys.id || '';

    const formattedContents = this.#contentfulBaseService.formatContentfulRichBlockContent(contentfulArticle.contents);
    const [biographyImage, category] = await Promise.all([
      this.#contentfulBaseService.getCloudinaryImage(biographyImageId),
      categoryId ? this.#getCategoryContent(categoryId) : Promise.resolve(undefined),
    ]);

    const biographyImageUrl = biographyImage[0]?.asset?.fields.file?.url;
    const biographyContent = contentfulArticle.biographyContent;
    const articleContent: ContentfulArticleContent = {
      meta: contentfulArticle.metadata,
      overview: contentfulArticleOverview,
      biography: biographyContent
        ? {
            name: contentfulArticle.biographyContent?.fields.name || '',
            title: contentfulArticle.biographyContent?.fields.title || '',
            classNames: contentfulArticle.biographyContent?.fields.classNames || '',
            image: biographyImageUrl,
            description: contentfulArticle.biographyContent?.fields.description || '',
            articleListsForBiographyModal: contentfulArticle.articleListsForBiographyModal.map((item: any) => ({
              title: item.fields.title || '',
              id: item.fields.slug.sys.id || '',
              slug: item.fields.slug.fields.slug || '',
            })),
          }
        : undefined,
      contents: formattedContents,
      relatedProducts: [],
      relatedArticles: [],
      articleShareContent: this.#getArticleShareContent(contentfulArticle),
    };

    if (category) {
      articleContent.category = category;
    }

    return articleContent;
  }

  //TODO: Used by another method, check if we can remove it on the other function too
  async #getArticleOverviewContent(contentId: string): Promise<ContentfulArticleOverviewContent> {
    // Added include parameter to get the resolved link fields for imageRendition
    // With new Contentful Skeleton interfaces, we have links entries to other entries
    // To resolve imageRendition we need to include two levels of links: ContentfulArticleOverviewContent -> ContentfulCloudinaryImage -> ContentfulImageRendition
    // This is necesary to get the imageRendition fields from the thumbnailImage that we are checking in the condition of the return
    // More info: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/links
    let articleOvierviewContent: ContentfulArticleOverviewContent = {
      name: '',
      title: 'empty-content',
      description: '',
      slug: '',
    };

    try {
      const contentfulQuery = {
        content_type: 'articleOverview',
        'sys.id': contentId,
        select: 'fields.name, fields.title, fields.description, fields.thumbnailImage, fields.mobileThumbnail, fields.slug',
        include: 2 as const,
      };
      const contentfulClient = await this.#contentfulClientService.getClient();
      const articleOverviewEntries = await contentfulClient.getEntries<ContentfulArticleOverviewSkeleton>(contentfulQuery);
      const fieldContent = articleOverviewEntries.items[0].fields;

      let thumbnailImage = this.#resolveCloudinaryImageFromEntry(fieldContent.thumbnailImage);
      let mobileThumbnail = this.#resolveCloudinaryImageFromEntry(fieldContent.mobileThumbnail);
      let slug = isAContentfulResolvedLink(fieldContent.slug) ? fieldContent.slug : undefined;

      if (articleOverviewEntries.total === 0) {
        this.#contentfulBaseService.notifyContentfulEmptyResults(contentfulQuery);
        return articleOvierviewContent;
      }

      articleOvierviewContent = {
        name: fieldContent.name,
        title: fieldContent.title,
        description: fieldContent.description,
        thumbnailImage: thumbnailImage,
        mobileThumbnail: mobileThumbnail,
        thumbnailImageUrl: thumbnailImage?.asset?.fields.file?.url,
        slug: slug?.fields.slug,
      };

      return articleOvierviewContent;
    } catch (error) {
      if (error instanceof Error) {
        throw new ArticleOverviewContentException(error.message, { cause: error });
      }

      throw error;
    } finally {
      this.#logger.debug('ContentfulArticleService.getArticleOverviewContent', articleOvierviewContent);
    }
  }

  async #getProductOverviewContent(contentId: string): Promise<ContentfulProductOverviewContent> {
    let productOverviewContent: ContentfulProductOverviewContent = {
      title: 'empty-content',
      description: '',
      slug: '',
    };

    const contentfulQuery = {
      content_type: 'productOverview',
      'sys.id': contentId,
    };
    const contentfulClient = await this.#contentfulClientService.getClient();
    const productOverviewEntries = await contentfulClient.getEntries<ContentfulProductOverviewSkeleton>(contentfulQuery);
    const contentfulProductOverviewItems: ContentfulProductOverview = productOverviewEntries.items[0]?.fields;
    const productThumbnailImage: ContentfulCloudinaryImage = contentfulProductOverviewItems?.mainAsset.fields;

    if (productOverviewEntries.total === 0) {
      this.#contentfulBaseService.notifyContentfulEmptyResults(contentfulQuery);
      return productOverviewContent;
    }

    productOverviewContent = {
      title: contentfulProductOverviewItems.uniqueId,
      description: contentfulProductOverviewItems.description,
      thumbnailImage: productThumbnailImage,
      // FIXME: Using non-null assertion in asset. This can fail if contentful is configured incorrectly, but it is not a problem for now
      thumbnailImageUrl: productThumbnailImage.asset!.fields.file?.url,
      slug: contentfulProductOverviewItems.slug.fields.slug,
    };

    return productOverviewContent;
  }

  //TODO: Used by another method, check if we can remove it on the other function too
  async #getCategoryContent(contentId: string): Promise<ContentfulCategoryContent> {
    let categoryContent: ContentfulCategoryContent = {
      name: '',
      title: 'empty-content',
      slug: '',
    };

    try {
      const contentfulQuery = {
        content_type: 'category',
        'sys.id': contentId,
      };
      const contentfulClient = await this.#contentfulClientService.getClient();
      const categoryEntries = await contentfulClient.getEntries<ContentfulCategorySkeleton>(contentfulQuery);
      const category = categoryEntries.items[0].fields;

      if (categoryEntries.total === 0 || !isAContentfulResolvedLink(category.slug)) {
        this.#contentfulBaseService.notifyContentfulEmptyResults(contentfulQuery);
        return categoryContent;
      }

      categoryContent = {
        name: category.name,
        title: category.title,
        slug: category.slug.fields.slug,
      };

      return categoryContent;
    } catch (error) {
      if (error instanceof Error) {
        throw new CategoryContentException(error.message, { cause: error });
      }

      throw error;
    } finally {
      this.#logger.debug('ContentfulArticleService.getCategoryContent', categoryContent);
    }
  }
}
