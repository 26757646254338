import { Route } from '@angular/router';

export interface CustomRoute extends Route {
  shouldReuse?: ReuseStrategyType;
}

export enum ReuseStrategyType {
  always,
  never,
  onDiffRoutes,
  onSameRoutes,
  default,
}
