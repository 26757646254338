import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StoreSpecificConfigService } from '@scalefast/config-angular';
import { GenericLogger } from '@scalefast/ecommerce-core';

interface ScriptInjectorConfig {
  id: string;
  async: boolean;
  defer: boolean;
  src: string;
  injectOnSSR: boolean;
  thirdParty: boolean;
  type?: string;
  onLoad?: () => void;
  onError?: () => void;
}

@Injectable({ providedIn: 'root' })
export class ScriptInjectorService {
  private readonly SCRIPT_TYPE = 'text/javascript';

  private injectedScripts: string[] = [];

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private genericLogger: GenericLogger,
    private storeSpecificConfigService: StoreSpecificConfigService,
  ) {}

  private shouldInjectScript(config: ScriptInjectorConfig): boolean {
    if (!config.src || !config.id) {
      throw new Error('ScriptInjectorService: src and id are required parameters');
    }
    if (config.thirdParty && this.storeSpecificConfigService.get().disableThirdPartyScripts === 'true') {
      return false;
    }
    const checkNotDuplicated: boolean = !this.injectedScripts.includes(config.src);
    if (!checkNotDuplicated) {
      this.genericLogger.error(`ScriptInjectorService: Script ${config.src} already injected`);
      return false;
    }
    if (isPlatformServer(this.platformId)) {
      return config.injectOnSSR;
    }
    return true;
  }

  /**
   * Inject a script into the DOM
   * @param config params to configure script injection options
   * @returns if the script was injected or discarded based con config/environment
   */
  injectScript(config: ScriptInjectorConfig): boolean {
    if (!this.shouldInjectScript(config)) {
      return false;
    }
    const _script = this._document.createElement('script');
    _script.id = config.id;
    _script.src = config.src;
    _script.async = config.async;
    _script.defer = config.defer;
    _script.type = config.type ?? this.SCRIPT_TYPE;
    if (config.onLoad) {
      _script.onload = config.onLoad;
    }
    if (config.onError) {
      _script.onerror = config.onError;
    }
    //Insert script before first page script on DOM tree.
    const firstScript = this._document.getElementsByTagName('script')[0];
    if (firstScript) {
      firstScript.parentNode?.insertBefore(_script, firstScript);
      this.injectedScripts.push(config.src);
    }
    return true;
  }
}
