import { inject, Injectable } from '@angular/core';
import { GenericLogger } from '@scalefast/ecommerce-core';
import type { Asset, EntryCollection } from 'contentful';
import { ContentfulCloudinaryImage } from 'src/app/core/interfaces/Contentful/CloudinaryImage/ContentfulCloudinaryImage';
import { ContentfulCloudinaryImageSkeleton } from 'src/app/core/interfaces/Contentful/CloudinaryImage/ContentfulCloudinaryImageSkeleton';
import { NoChainModifier } from 'src/app/core/interfaces/Contentful/ContentfulChainModifier';
import { ContentfulImageRendition } from 'src/app/core/interfaces/Contentful/ImageRendition/ContentfulImageRenditionSkeleton';
import { isAContentfulResolvedAsset, isAContentfulResolvedLink } from '../helpers/contentful.helper';
import { ContentfulClientService } from './contentful-client.service';
import { ContentfulEmbeddedBlockData } from 'src/app/core/interfaces/Contentful/EmbeddedBlockData/ContentfulEmbeddedBlockData';
import {
  ContentfulRichBlockContent,
  ContentfulRichBlockContents,
  ContentfulRichBlockTextContent,
} from 'src/app/core/interfaces/Contentful/RichBlock';
import { ContentfulRichBlockParagraph } from 'src/app/core/interfaces/Contentful/RichBlock/ContentfulRichBlockParagraph';

@Injectable({
  providedIn: 'root',
})
export class ContentfulBaseService {
  #logger = inject(GenericLogger);
  #contentfulClientService = inject(ContentfulClientService);

  notifyContentfulEmptyResults(contentfulQuery: unknown) {
    this.#logger.warn({ msg: 'The query made with contentful did not return any results', contentfulQuery });
  }

  //TODO: This function is called N times, check why
  getEmbeddedBlockFromEntry(entry: any, blockContentType?: string): ContentfulEmbeddedBlockData {
    const blockId = entry.sys.id;
    if (!blockContentType) {
      blockContentType = entry.sys.contentType.sys.id;
    }
    switch (blockContentType) {
      case 'responsiveImageBlock': {
        return {
          url: entry.fields.desktopImage.fields.asset.fields.file.url,
          mediaType: 'image',
        };
      }
      case 'contentBlock': {
        const backgroundAsset = entry.fields.devicesConfigurations[0].fields?.backgroundAsset;
        const isVideo = !!backgroundAsset?.fields?.videoSettings;
        const file = backgroundAsset?.fields?.asset?.fields.file;
        let url = file?.url;
        let mediaType = isVideo ? 'video' : 'image';

        if (!isAContentfulResolvedLink(backgroundAsset)) {
          this.#logger.warn({
            msg: 'Contentful with unresolved link. Returning image media-type without url',
            sys: backgroundAsset?.sys,
          });
        }

        return {
          url,
          mediaType,
        };
      }
      case 'disclaimer': {
        return {
          content: entry.fields?.disclaimerText,
          mediaType: 'disclaimer',
        };
      }
      case 'cloudinaryImage': {
        const contentfulCloudinaryImage = entry.fields;
        // We have identified a scenario where the asset is missing from the contentfulCloudinaryImage object.
        // In this case, we display a warning and return an object without image data, ensuring it is not rendered in the component
        if (!contentfulCloudinaryImage?.asset) {
          const errorMessage =
            contentfulCloudinaryImage.length === 0
              ? `No image found for blockId: ${blockId}`
              : `No asset found for image: ${contentfulCloudinaryImage.name}`;

          const error = new Error(errorMessage);
          this.#logger.warn({ msg: 'Contentful: notify warn', error });
          return { url: '', mediaType: '' };
        }
        return {
          url: contentfulCloudinaryImage.asset!.fields.file?.url,
          mediaType: 'image',
        };
      }
      default:
        return { url: '', content: '', mediaType: '' };
    }
  }

  formatContentfulRichBlockContent(
    contentfulRichBlockContents: Array<ContentfulRichBlockContents>,
  ): Array<ContentfulRichBlockContent> {
    const formattedContents: Array<ContentfulRichBlockContent> = [];

    for (const contents of contentfulRichBlockContents) {
      const anchorId: string = contents.fields.anchorId;
      const text: ContentfulRichBlockTextContent[] = contents.fields.text?.content;
      const classNames: string = contents.fields.classNames ?? '';
      const textContent: string = contents.fields.textContent ?? '';

      if (text) {
        text.forEach((contentfulRichBlockTextContent: ContentfulRichBlockTextContent) => {
          const blockContent: ContentfulRichBlockParagraph[] = contentfulRichBlockTextContent.content;
          const nodeType = contentfulRichBlockTextContent.nodeType;

          if (nodeType === 'embedded-entry-block') {
            const imageId = contentfulRichBlockTextContent.data?.target.sys.id;

            if (imageId) {
              const embeddedBlock: ContentfulEmbeddedBlockData = this.getEmbeddedBlockFromEntry(
                contentfulRichBlockTextContent.data?.target,
              );

              contentfulRichBlockTextContent.imageId = imageId;
              contentfulRichBlockTextContent.nodeType = nodeType;
              const contentfulRichBlockParagraph: ContentfulRichBlockParagraph = {
                nodeType,
                value: embeddedBlock.mediaType,
                data: {
                  uri: embeddedBlock.url,
                },
                disclaimerText: embeddedBlock.content,
              };
              contentfulRichBlockTextContent.content[0] = contentfulRichBlockParagraph;
            }
          } else {
            // Return a default object if the condition is not met
            contentfulRichBlockTextContent.nodeType = nodeType;
            contentfulRichBlockTextContent.content = blockContent;
          }
        });
      }

      formattedContents.push({
        anchorId,
        classNames,
        content: contents.fields.text?.content ?? [],
        textContent,
      });
    }

    return formattedContents;
  }

  async getCloudinaryImage(contentId: string): Promise<ContentfulCloudinaryImage[]> {
    if (!contentId || contentId.trim() === '' || contentId === 'empty-content') {
      this.#logger.warn('ContentfulBaseService.getCloudinaryImage', 'Invalid contentId provided', contentId);
      return [];
    }

    const contentfulQuery = {
      content_type: 'cloudinaryImage',
      'sys.id': contentId,
    };

    const contentfulClient = await this.#contentfulClientService.getClient();
    const contentfulCloudinaryImageEntries: EntryCollection<ContentfulCloudinaryImageSkeleton, NoChainModifier> =
      await contentfulClient.getEntries<ContentfulCloudinaryImageSkeleton>(contentfulQuery);

    if (contentfulCloudinaryImageEntries.total === 0) {
      this.notifyContentfulEmptyResults(contentfulQuery);
      return [];
    }

    return contentfulCloudinaryImageEntries.items.map((contentfulCloudinaryImageEntry) => {
      let imageAsset: Asset<NoChainModifier> | undefined = undefined;
      if (isAContentfulResolvedAsset(contentfulCloudinaryImageEntry.fields.asset)) {
        imageAsset = contentfulCloudinaryImageEntry.fields.asset;
      }

      let imageRendition: ContentfulImageRendition | undefined = undefined;
      if (isAContentfulResolvedLink(contentfulCloudinaryImageEntry.fields.imageRendition)) {
        imageRendition = contentfulCloudinaryImageEntry.fields.imageRendition.fields;
      }

      return {
        alternateText: contentfulCloudinaryImageEntry.fields.alternateText,
        asset: imageAsset,
        imageRendition: imageRendition!, // Non-null assertion is safe because imageRendition is required in the CloudinaryImage content type of Contentful
        isPreload: contentfulCloudinaryImageEntry.fields.isPreload,
        name: contentfulCloudinaryImageEntry.fields.name,
      };
    });
  }

  getDefaultImageAssetUrl(message: string): string {
    this.#logger.warn(`${message} Using default image.`);
    return 'assets/images/article-list.jpg';
  }
}
