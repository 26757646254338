import { ActivatedRouteSnapshot } from '@angular/router';
import { IPageHandler } from './page-handler.interface';
import { Page } from 'src/app/core/interfaces';

export abstract class AbstractPageHandler implements IPageHandler {
  protected nextHandler: IPageHandler | null = null;

  public setNext(handler: IPageHandler): IPageHandler {
    this.nextHandler = handler;
    return handler;
  }

  public async handle<T>(route: ActivatedRouteSnapshot): Promise<Page<T> | null> {
    if (this.nextHandler) {
      return this.nextHandler.handle(route);
    }
    return null;
  }
}
