import { Injectable } from '@angular/core';
import { EventManager } from './event-manager.service';
import { EventTypeEnumerate } from '../../core/enumerates';

@Injectable({ providedIn: 'root' })
export class GenericEventService extends EventManager {
  public emitMenuClick(status: boolean): void {
    this.broadcast({ name: EventTypeEnumerate.APP_MENU_CLICK, status: status });
  }

  public clickProductLink(): void {
    this.broadcast({ name: EventTypeEnumerate.APP_MINICART_ITEM_CLICK });
  }

  public emitSearchProductClick(): void {
    this.broadcast({ name: EventTypeEnumerate.APP_SEARCH_ITEM_CLICK });
  }
}
