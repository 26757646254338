import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';

import { BuilderContent } from '@builder.io/sdk';
import { BuilderConfigService } from '@scalefast/config-angular';
import { MeCachisDependencyService } from '@scalefast/core';
import { MECACHIS_DEPENDENCY_SERVICE } from '@scalefast/ecommerce-core';
import { Observable, map, of, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuilderContentService {
  isServer: boolean;
  builderIOApiKey: string;

  constructor(
    private transferState: TransferState,
    private httpClient: HttpClient,
    private builderConfigService: BuilderConfigService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(MECACHIS_DEPENDENCY_SERVICE) private mecachisDependencyService: MeCachisDependencyService,
  ) {
    this.builderIOApiKey = this.builderConfigService.get().builderIOApiKey;
    this.isServer = isPlatformServer(this.platformId);
  }

  /**
   * Get the content from Builder.io by contentId.
   * If the content is already in the transfer state, it will return it from there.
   * @param contentId string
   * @returns Observable<BuilderContent[]>
   */
  getBuilderContent(contentId: string, allContent = false): Observable<BuilderContent[]> {
    return this.httpClient
      .get(`https://cdn.builder.io/api/v3/content/${contentId}?apiKey=${this.builderIOApiKey}&limit=100`)
      .pipe(
        map((builderContent: any) => {
          return (allContent ? [...builderContent.results] : [builderContent.results[0]]) as BuilderContent[];
        }),
        tap((builderContent) => {
          this.mecachisDependencyService.addBuilderDependency(contentId);
        }),
      );
  }

  getPageMetaBySlugHttp(contentId: string, options?: any): Observable<BuilderContent[]> {
    if (options) {
      options.apiKey = this.builderIOApiKey;
    } else {
      options = {
        apiKey: this.builderIOApiKey,
      };
    }
    const apiUrl = `https://cdn.builder.io/api/v3/query/${this.builderIOApiKey}/${contentId}`;
    return this.httpClient.get(apiUrl, { params: options }).pipe(
      map((content: any) => {
        return content[contentId] as BuilderContent[];
      }),
    );
  }

  // FIXME: same contenId can be used it for pages with different date
  getPageMetaBySlug(contentId: string, options?: any): Observable<BuilderContent[]> {
    const stateKey = makeStateKey<BuilderContent[]>(contentId);

    if (this.transferState.hasKey(stateKey)) {
      return of(this.transferState.get(stateKey, null) as BuilderContent[]);
    }

    return this.getPageMetaBySlugHttp(stateKey, options);
  }
}
