import { PageLayout } from 'src/app/layout/layout.enum';

export interface PageInfo<T> {
  id: PageLayout;
  data: T | null;
}

export interface Page<T> {
  pageProvider: string;
  name: string;
  type: string;
  pageType: PageLayout;
  extraData?: T;
}

export class PageComponents {
  global: boolean = true;
  advantage: boolean = true;
  footer: boolean = true;
  header: boolean = true;
  globalTimer: boolean = true;
  newsletter: boolean = true;
  breadcrumbs: boolean = true;
  customComponents: any | null = null;

  static fromBuilder(data: any): PageComponents {
    const model = new PageComponents();
    model.global = data.global ?? false;
    model.advantage = data.advantage ?? false;
    model.footer = data.footer ?? false;
    model.header = data.header ?? false;
    model.globalTimer = data.globalTimer ?? true;
    model.newsletter = data.newsletter ?? false;
    model.breadcrumbs = data.breadcrumbs ?? false;
    model.customComponents = data.customComponents ?? [];

    return model;
  }
}
