import { StateKey, inject, makeStateKey } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { PageLayoutType } from 'src/app/core/enumerates/page-layout-type.enumerate';
import { Page, PageComponents } from 'src/app/core/interfaces';
import { PageLayout } from 'src/app/layout/layout.enum';
import { ServerNavigateService } from 'src/app/shared/services/server-navigate.service';
import { AbstractPageHandler } from './page-handler-abstract';
import { GenericLogger } from '@scalefast/ecommerce-core';
import {
  getContentfulConfig,
  isAEmptyContentfulPagePathContent,
  isAnErrorContentfulPagePathContent,
} from 'src/app/shared/helpers/contentful.helper';
import { ContentfulConfigService, StoreSpecificConfigService } from '@scalefast/config-angular';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ContentfulPagePathContent } from 'src/app/core/interfaces/Contentful/PagePath/ContentfulPagePathContent';
import { ContentfulSectionContent } from 'src/app/core/interfaces/Contentful/ContentfulSectionContent';

export class PageContenfulHandler extends AbstractPageHandler {
  private httpClient = inject(HttpClient);
  private logger = inject(GenericLogger);
  private router = inject(Router);
  private serverNavigateService = inject(ServerNavigateService);
  private contentfulConfigService = inject(ContentfulConfigService);
  private storeSpecificConfigService = inject(StoreSpecificConfigService);
  private contentfulSpace: string;
  private contentfulAccessToken: string;
  private contentfulLocale: string;

  constructor() {
    super();
    const contentfulConfig = getContentfulConfig(this.contentfulConfigService);

    this.contentfulSpace = contentfulConfig.space;
    this.contentfulAccessToken = contentfulConfig.accessToken;
    this.contentfulLocale = contentfulConfig.locale;
  }

  override async handle<Extradata>(route: ActivatedRouteSnapshot): Promise<Page<Extradata> | null> {
    // The page is reloaded when the user accesses it to avoid slow loading times. This reload causes the requested page data to be performed on the server side.
    if (this.router.navigated) {
      await this.serverNavigateService.navigateToRoute(route);
    }

    let contentfulPagePathContent = await this.resolveContentfulPagePath(route);
    if (!this.isAContentfulPagePathContentValid(contentfulPagePathContent)) {
      return super.handle(route);
    }

    const routeParams = Object.values(route.params);
    return {
      pageProvider: 'contenful',
      name: '',
      type: PageLayoutType.CONTENTFUL_PAGE,
      pageType: PageLayout.Default,
      extraData: {
        routeParams,
        contentfulPagePathContent,
        components: new PageComponents(),
      },
    } as Page<Extradata>;
  }

  private isAContentfulPagePathContentValid(contentfulPagePathContent: ContentfulPagePathContent): boolean {
    return (
      !isAEmptyContentfulPagePathContent(contentfulPagePathContent) &&
      !isAnErrorContentfulPagePathContent(contentfulPagePathContent)
    );
  }

  /**
   * Resolves the page using the Contentful API.
   * If the page is not found, it returns an empty object.
   *
   * NOTE: This request is not part of any Contentful service because we want to avoid
   * creating the Contentful service instance if the page being loaded is not a Contentful page.
   * This decision helps in optimizing resource usage and performance when non-Contentful pages are requested.
   */
  async resolveContentfulPagePath(route: ActivatedRouteSnapshot): Promise<ContentfulPagePathContent> {
    const slug = this.prepareSlug(route.params);

    try {
      if (this.isBlocked(slug)) {
        this.logger.info('Page is blocked');
        return {
          id: 'empty-content',
          locale: '',
        };
      }

      const contentfulPagePath = await this.fetchToContentfulPagePath(slug);
      if (contentfulPagePath.total <= 0) {
        return {
          id: 'empty-content',
          locale: '',
        };
      }

      return {
        id: contentfulPagePath.items[0].sys.id,
        locale: contentfulPagePath.items[0].sys.locale,
      };
    } catch (error) {
      this.logger.error({ msg: 'Contentful: notify error', error });
      return { id: 'error', locale: '' };
    }
  }

  private prepareSlug(routeParams: Params): string {
    const localeSlugs = ['en-en', 'es-es', 'fr-fr'];
    const completeRouteSlug = PageContenfulHandler.getCompleteSlug(routeParams);
    let fullSlug: string;

    if (localeSlugs.includes(completeRouteSlug.split('/')[0]) || localeSlugs.includes(completeRouteSlug.split('/')[1])) {
      this.logger.error(
        `Contentful URL ${completeRouteSlug} resolved with the baseURL locale, all current production/dev modes should not have baserUrl`,
      );
    }

    // HACK: contentful Locale matches baseURL just by luck but we probably will remove this code in the future
    fullSlug = '/' + this.contentfulLocale.toLowerCase() + '/' + completeRouteSlug;

    // remove duplicated slashes
    return fullSlug.replace(/\/{2,}/g, '/');
  }

  private fetchToContentfulPagePath(slug: string): Promise<any> {
    const url = `https://cdn.contentful.com/spaces/${this.contentfulSpace}/environments/master/entries`;
    const params = {
      include: 0,
      content_type: 'pagePath',
      'fields.slug[in]': slug,
      select: 'sys',
      access_token: this.contentfulAccessToken,
    };

    return firstValueFrom(this.httpClient.get(url, { params }));
  }

  /**
   * Blocks the rendering of a page if it's slug is in the blocklist
   * Used to prevent the rendering of pages that are not ready in production
   * It can be configured by environment so the blocklist is disabled while developing and testing
   */
  private isBlocked(slug: string): boolean {
    const blocklist = this.storeSpecificConfigService.get().contentful.blocklist;
    if (!blocklist.enabled) {
      return false;
    }

    return blocklist.slugs.includes(slug);
  }

  public static getContentFullContentStateKey(routeParams: any[]): StateKey<ContentfulSectionContent[]> {
    return makeStateKey(`resolver:contentfull-${routeParams.toString()}`);
  }

  public static getCompleteSlug(routeParams: Params): string {
    let contentSlug = '';
    Object.values(routeParams).forEach((value) => {
      contentSlug = contentSlug + `/${value}`;
    });
    return contentSlug;
  }
}
