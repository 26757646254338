import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FooterLinksService {
  constructor() {}

  protected footerLinks: any = {
    'es-ES': [
      {
        title: 'Tienda',
        links: [
          {
            title: 'Cepillos eléctricos',
            link: 'cepillos-electricos',
          },
          {
            title: 'Recambios',
            link: 'recambios',
          },
          {
            title: 'Pasta dentífrica',
            link: 'otros-productos/pasta-dentifrica',
          },
          {
            title: 'Irrigadores',
            link: 'otros-productos/irrigadores',
          },
          {
            title: 'Cepillos eléctricos para niños',
            link: 'ninos/cepillos-electricos',
          },
          {
            title: 'Enjuague bucal',
            link: 'otros-productos/enjuague-bucal',
          },
          {
            title: 'Hilo dental',
            link: 'otros-productos/hilo-dental',
          },
        ],
      },
      {
        title: 'Sitios relacionados',
        links: [
          {
            title: 'Marcas P&G',
            link: 'https://es.pg.com/marcas-y-productos/',
          },
          {
            title: 'Oral-B Profesional',
            link: 'https://www.oralbprofessional.es/s/?language=es',
          },
          {
            title: 'Kukident',
            link: 'https://kukident.es/es-es',
          },
          {
            title: 'Salud Oral',
            link: 'https://www.oralb.es/es-es/salud-oral',
          },
          {
            title: 'Próxima a ti',
            link: 'https://www.proximaati.com',
          },
          {
            title: 'Braun',
            link: 'https://es.braun.com/es-es',
          },
        ],
      },
      {
        title: 'Contacta con nosotros',
        links: [
          {
            title: 'Atención al cliente',
            link: 'https://oral-bshop.zendesk.com/hc/es',
          },
          {
            title: '¿Preguntas?',
            link: 'faq',
          },
          {
            title: 'Servicio Oral-B',
            link: 'https://www.service.oralb.com/es/es/',
          },
          {
            title: '30 días de prueba gratis',
            link: '30-dias-de-prueba-gratis',
          },
          {
            title: 'Active su garantía',
            link: 'account',
          },
        ],
      },
    ],
    'fr-FR': [
      {
        title: 'Boutique',
        links: [
          {
            title: 'Brosses à dents électriques',
            link: 'brosses-a-dents-electriques',
          },
          {
            title: 'Brossettes de rechange',
            link: 'brossettes-de-rechange',
          },
          {
            title: 'Hydropulseurs',
            link: 'plus-de-produits/hydropulseurs',
          },
          {
            title: 'Brosses à dents enfant',
            link: 'enfants/brosses-a-dents-electriques',
          },
        ],
      },
      {
        title: 'Sites connexes',
        links: [
          {
            title: 'Marques P&G',
            link: 'https://fr.pg.com/marques-et-produits/',
          },
          {
            title: 'Oral-B Professional',
            link: 'https://www.oralbprofessional.fr/s/?language=fr',
          },
          {
            title: 'Fixodent',
            link: 'https://www.fixodent.fr/fr-fr',
          },
          {
            title: 'Envie de Plus',
            link: 'https://www.enviedeplus.com',
          },
          {
            title: 'Braun',
            link: 'https://fr.braun.com/fr-fr',
          },
        ],
      },
      {
        title: 'Notre ambition',
        links: [
          {
            title: 'Recyclage (y compris les équipements électriques et électroniques)',
            link: 'docs/cgv#recycling',
          },
        ],
      },
      {
        title: 'Contactez-nous',
        links: [
          {
            title: 'Service Client',
            link: 'https://oral-bshop.zendesk.com/hc/fr',
          },
          {
            title: 'Questions?',
            link: 'faq',
          },
          {
            title: 'Service',
            link: 'https://www.service.oralb.com/fr/fr/',
          },
          {
            title: '30 jours, Satisfait ou remboursé',
            link: 'https://www.satisfaitourembourse-oralb.fr/',
          },
          {
            title: 'Activez votre garantie',
            link: 'account',
          },
        ],
      },
    ],
  };

  public getFooterLinks(): any {
    return this.footerLinks;
  }
}
